@import '../../theme/variables';

.root {
  height: 100vh;
  min-height: 500px;
}

.content {
  background-color: #ededed;
}

.title {
  text-align: center;
  padding: 1rem;
}

.formWrap {
  width: 40%;
  margin: 2rem auto;
  padding: 2.5rem 1rem 1rem;
  border-radius: 3px;
  background-color: #f8f8f8;
}
