.mainWrap {
  display: flex;
  justify-content: space-between;
}

.wrapElem {
  min-width: 45%;
  &__small{
    min-width: 40%;
  }
  &__big{
    min-width: 80%;
  }
}

.addWrap {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.addNew {
  &__icon {
    font-size: 1.5rem;
  }
  &__text {
    margin-left: 0.5rem;
  }
}

.goBack {
  &__icon {
    font-size: 1.2rem;
  }
}
