.addWrap {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.addNew {
  &__icon {
    font-size: 1.5rem;
  }
  &__text {
    margin-left: 0.5rem;
  }
}

.goBack{
  &__icon{
    font-size: 1.2rem;
  }
}