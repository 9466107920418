@import '../../theme/variables';

.root {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 0 1.2rem;
}

.title {
  font-size: 1.2rem;
  color: $oxford-blue;
  transition: color 1s ease;
  cursor: default;
  &:hover {
    color: $indigo;
  }
}

.logout{
  cursor: pointer;
  transition: color .5s ease;
  &:hover {
    color: $indigo;
  }
}
