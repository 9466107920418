@import '../../theme/variables';

.actionBtn {
  margin-right: 8px;
  cursor: pointer;
  &__save {
    color: $success;
  }
  &__del {
    color: $danger;
  }
  &__cancel {
    color: gray;
  }
}
