// Colors
$dark-chocolate: #211D0A;
$oxford-blue: #081340;
$indigo: #361470;
$camel: #BC955C;
$maj-blue: #5C45F5;

$success:#7cb305;
$warning:#fadb14; 
$danger: #ff7875;


// media breakpoints
$small-desktop: '992px';
$tablet: '768px';
$mobile: '450px';