@import '../../theme/variables';

.root{
  height: 100vh;
}

.logo{
  width: 100%;
  height: 64px;
}

